import { Injectable } from '@angular/core';
import { Profile } from './profile';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from './app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  getProfile(type?: string, id?: string | number): Observable<Profile> {
    let params;
    const url = '/webapi/rest/profile';
    if (type || id) {
      params = new HttpParams();
      if (type) {
        params = params.set('type', type);
      }
      if (id) {
        params = params.set('id', id);
      }
      return this.http.get<Profile>(url, { params: params });
    } else {
      return this.http.get<Profile>(url);
    }
  }

  ping(id: string | number): Observable<any> {
    let params = new HttpParams().set('id', id);
    const url = '/webapi/rest/profile/ping';
    return this.http.get(url, { params: params });
  }

  getConfiguration(): Observable<AppConfig> {
    const url = '/webapi/rest/config';
    return this.http.get<AppConfig>(url);
  }

  getLoginUrl(): Observable<any> {
    const url = '/webapi/rest/config/loginUrl';
    return this.http.get(url);
  }

  logout(): Observable<any> {
    const url = '/webapi/rest/config/logoutUrl';
    return this.http.get(url);
  }

  getTranslation(language: string): Observable<any> {
    const url = `/assets/i18n/${language}.json`;
    return this.http.get(url);
  }

}
