import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/auth/auth.service';
import { Router } from '@angular/router';

import { NavbarService } from './core/navbar/navbar.service';
import { Location, LocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { ProfileService } from './core/auth/profile.service';
import { WindowRef } from './core/domain/window-ref';
import { GtmCustomDimensions } from './core/auth/gtm-custom-dimensions';
import { DomService } from './core/dom/dom.service';
import { Subscription } from 'rxjs';
import { LayoutDataService } from './layout-data.service';

@Component({
  selector: 'admin-root',
  providers: [Location, { provide: APP_BASE_HREF, useValue: '/' }, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  gtm: GtmCustomDimensions = {
    currentContextId: null,
    contextTypes: null,
    currentContextType: null,
    customerType: null,
    distributor: null
  };

  private defaultTranslation$ = new Subscription();
  private profile$ = new Subscription();
  private config$ = new Subscription();
  private userTranslation$ = new Subscription();


  constructor(private location: Location, private auth: AuthService, private dom: DomService,
    private pfs: ProfileService, private router: Router, private layoutDataService: LayoutDataService,
    private windowRef: WindowRef, private navbar: NavbarService) {
  }

  ngOnInit(): void {
    this.navbar.handleDirectLink(this.location.path());

    const id = this.navbar.getContextId(this.windowRef.getSearch());
    const type = this.navbar.getContextType(this.windowRef.getPathname());

    this.defaultTranslation$ = this.auth.getTranslation(environment.defaultLanguage).subscribe(tnl => {
      this.profile$ = this.auth.getProfile(type, id).subscribe(response => {
        if (response instanceof Array) {
          response = response[0];
        }
        this.pfs.profile = response;
        this.config$ = this.auth.getConfiguration().subscribe(config => {
          if (this.pfs.profile.id) {
            this.gtm = {
              currentContextId: this.pfs.profile.id,
              contextTypes: config.contextTypes,
              currentContextType: this.pfs.profile.type,
              customerType: config.customerType
            }
          }
          const language = this.pfs.getLanguage(environment.supportedLanguages,
            environment.defaultLanguage, this.pfs.profile.language);
          this.pfs.profile.language = language;

          this.userTranslation$ = this.auth.getTranslation(language).subscribe(result => {
            this.navbar.handleContextId(String(this.pfs.profile.id));
            this.pfs.config = config;
            this.pfs.messages = this.pfs.extend(tnl, result);
            const item = this.navbar.getProfileNavItem(this.pfs.profile, this.windowRef.getHostname());
            this.dom.hideNoProfileSpinner();
            this.dom.setPageTitle(item.path);
            this.layoutDataService.sendPath(item.path);
            this.router.navigateByUrl(item.path);
          });
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.defaultTranslation$.unsubscribe();
    this.profile$.unsubscribe();
    this.config$.unsubscribe();
    this.userTranslation$.unsubscribe();
  }
}




